import React, { useState } from "react";
import { Button, Box, TextField, Typography, Snackbar } from "@mui/material";
import { Search } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import axios from "axios";
import { fcaService } from "../service/FcaServices";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";

export const Home = () => {
	const { id } = useParams();
	console.log("parama----", id);
	const [value, setValue] = useState(0);

	const [age, setAge] = useState("");
	const [open, setOpen] = React.useState(false);
	const [data, setData] = useState("");
	const [address, setAddress] = useState("");
	const [trading, setTrading] = useState([]);

	const [status, setStatus] = useState("");

	const [fcaData, setFcaData] = useState({
		name: "",
		Address: "",
	});
	const navigate = useNavigate();

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (reason) => {
		setOpen(false);
	};
	const action = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={handleClose}>
				UNDO
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const handleFca = async () => {
		try {
			setData("");
			setStatus("");
			// let fca = fcaService.getFca(value);
			// console.log("fca", fca.data.Data[0]?.["Organisation Name"]);
			let res = "";

			var authOptions = {
				method: "post",
				url: "https://register.fca.org.uk/services/V0.1/Firm/" + value,
				headers: {
					"Access-Control-Allow-Origin": "*",
					Accept: "*",
					mode: "no-cors",
					"Content-Type": "application/json",
					"Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
					"Access-Control-Allow-Credentials": false,
					"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
					"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
					"Access-Control-Allow-Headers":
						"Accept, X-Requested-With, Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Origin, Content-Type, X-Auth-Token,Authorization",
				},
				// json: true,
			};
			await axios(authOptions)
				.then((response) => {
					console.log(response.data.Message);
					res = response.data;
					setData(response.data.Data[0]["Organisation Name"]);
					setStatus(response.data.Data[0]["Status"]);
					if (response.data.Message === "Firm not found") {
						setOpen(true);
					}
					var authOptions = {
						method: "post",
						url:
							"https://register.fca.org.uk/services/V0.1/Firm/" +
							value +
							"/Address",
						headers: {
							"Content-Type": "application/json",
							"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
							"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
						},
						json: true,
					};
					axios(authOptions)
						.then((response) => {
							console.log(response);
							setAddress(response.data.Data[0]);
							// return response.data;
						})
						.catch((error) => {
							console.log(error);

							return error;
						});
					// return response?.data;
				})
				.catch((error) => {
					return error;
				});
			console.log("res ", res);
			if (res.Message !== "Firm not found") {
				let name = fcaService.getCompanyName(value);
				let address = fcaService.getCompanyAddress(value);
				let permission = fcaService.getCompanyPermission(value);

				console.log("name", name);
				console.log("address", address);

				console.log("permission", permission);
			} else {
				setOpen(true);
			}
		} catch (error) {}

		// setValue(1);
		// Axios.post('https://register.fca.org.uk/services/V0.1/Firm/'+value).then((res)=>{
		//     if(res.data){
		//         Axios.post('https://register.fca.org.uk/services/V0.1/Firm/'+value).then((res)=>{
		//             if(res.data){

		//             }

		//         })
		//         Axios.post('https://register.fca.org.uk/services/V0.1/Firm/'+value+"/Names").then((res)=>{
		//             if(res.data){

		//             }

		//         })
		//         Axios.post('https://register.fca.org.uk/services/V0.1/Firm/'+value).then((res)=>{
		//             if(res.data){

		//             }

		//         })
		//     }

		// }).catch((error)=>{
		//     console.log("error")
	};
	console.log("address", address);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
				alignSelf: "center",
				width: "100%",
			}}
		>
			<Box width={"450px"}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Box width={"170px"}>
						<TextField
							fullWidth
							label="FCA Number"
							placeholder="12456"
							id="outlined-size-small"
							// defaultValue="Small"
							size="small"
							sx={{ borderRadius: "8px" }}
							onChange={(e) => {
								setValue(e.target.value);
							}}
						/>
					</Box>
					<Box>
						<Button
							variant="contained"
							color="primary"
							sx={{
								textTransform: "unset",
								borderRadius: "8px",
								width: "170px",
							}}
							startIcon={<Search />}
							onClick={handleFca}
						>
							Search
						</Button>
					</Box>
				</Box>
				<Box
					sx={{
						display: data ? "flex" : "none",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<Box sx={{ width: "200px", display: "flex" }}>
							<Typography fontWeight={600}>{"Company name"}</Typography>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Typography fontWeight={400}>{data}</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
						}}
					>
						<Box sx={{ width: "200px", display: "flex" }}>
							<Typography fontWeight={600}>{"Authorisation status"}</Typography>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Typography fontWeight={400}>{status}</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
						}}
					>
						<Box sx={{ width: "200px", display: "flex" }}>
							<Typography fontWeight={600}>{"Address"}</Typography>
						</Box>
						<Box sx={{ display: "flex" }}>
							<Typography fontWeight={400}>
								{/* {"Jackson House, Sale, M33 7RR"} */}
								{address["Address Line 1"] +
									" " +
									address["Town"] +
									" " +
									address["Postcode"]}
							</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
						}}
					>
						<Box sx={{ width: "200px", display: "flex" }}>
							<Typography fontWeight={600}>{"Trading name"}</Typography>
						</Box>
						<Box sx={{ display: "flex" }}>
							<FormControl sx={{ minWidth: 200 }}>
								<Select
									value={age}
									onChange={handleChange}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
									sx={{ height: "30px", display: "flex" }}
								>
									<MenuItem value=""> {""}</MenuItem>
									{/* <MenuItem value={10}>ABCD Corp</MenuItem>
									<MenuItem value={20}>ABCD Corp</MenuItem>
									<MenuItem value={30}>ABCD Corp</MenuItem> */}
								</Select>
							</FormControl>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
						}}
					>
						<Box sx={{ width: "200px", display: "flex" }}>
							<Typography fontWeight={600}>
								{"Correspondence address "}
							</Typography>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							height: "50px",
							alignItems: "center",
						}}
					>
						<Box sx={{ width: "450px" }}>
							<TextField
								minRows={5}
								fullWidth
								value={
									address["Address Line 1"] +
									" " +
									address["Address Line 2"] +
									" " +
									address["Address LIne 3"] +
									" " +
									address["Address Line 4"] +
									"" +
									+address["Town"] +
									" " +
									address["Postcode"] +
									" " +
									address["Country"]
									// "Independence House, West Yorkshire, HD3 3HN24 St Ives Gardens, Consett, DH8 7PQ"
								}
							/>
						</Box>
					</Box>

					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							// height: "50px",pap
							width: "450px",
							mt: "15px",

							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Button
							variant="contained"
							color="primary"
							sx={{ textTransform: "unset", borderRadius: "8px" }}
							startIcon={
								<Box sx={{ display: "flex", flexDirection: "row" }}>
									<NavigateBeforeIcon />
								</Box>
							}
							onClick={handleFca}
						>
							Back
						</Button>
						<Button
							variant="contained"
							color="primary"
							sx={{ textTransform: "unset", borderRadius: "8px" }}
							endIcon={
								<Box sx={{ display: "flex", flexDirection: "row" }}>
									<NavigateNextIcon />
								</Box>
							}
							onClick={() => {
								navigate(id === "config" ? "/regsiter/vendor" : "/regsiter");
							}}
						>
							Next
						</Button>
					</Box>
				</Box>
			</Box>
			<Snackbar
				vertical="top"
				horizontal="right"
				open={open}
				autoHideDuration={6000}
				onClose={handleClose}
				message="Firm not found"
				action={action}
			/>
		</Box>
	);
};
