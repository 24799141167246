import React from "react";
import APIURLConstants from "../config/config";
import axios from "axios";
import { Common } from "../components/Common";
import { useTheme } from "@mui/material/styles";
import { Button, Box, Divider, Typography } from "@mui/material";
import { chain, sortBy } from "lodash";
import CustomModal from "../components/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { NavigateNext } from "@mui/icons-material";
import { CommonConfig } from "../components/CommonConfig";

export const VendorRegistration = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [data, setData] = React.useState("");
	const [popUpData, setPopUpData] = React.useState([]);
	const [screenName, setScreenName] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const theme = useTheme();
	console.log(id);
	const getPageField = async () => {
		var themeOptions = {
			method: "get",
			url: APIURLConstants.GET_PAGE_FIELD,
			headers: {
				"Access-Control-Allow-Origin": "http://localhost:3000",
			},
			json: true,
		};
		await axios(themeOptions)
			.then((response) => {
				// console.log("theme", response.data);
				var result = chain(response.data)
					.groupBy((screen) => screen.screenId)
					.map((value, screen) => ({ screen, value })) //using ES6 shorthand to generate the objects
					.value();
				setData(result);
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	};
	const handlePopup = (scree, data) => {
		setPopUpData(data);
		setScreenName(
			Number(scree.screen) === 1
				? "Personal Details"
				: Number(scree.screen) === 2
				? "Login Details"
				: Number(scree.screen) === 3
				? "Admin Details"
				: "Intermediaries Terms, Conditions and Privacy Notice"
		);
		handleOpen();
	};
	const setFieldConfig = async (value, fieldId, screenId, type, inputType) => {
		console.log("fieldId", fieldId);
		const data = {
			screenId: screenId,
			fieldId: Number(fieldId),
			label: value,
			type: type,
			inputType: inputType,
		};
		console.log("Data---", data);
		const url =
			APIURLConstants.SET_PAGE_FIELD +
			"/" +
			value +
			"/" +
			Number(fieldId) +
			"/" +
			type +
			"/" +
			inputType;
		await axios
			.get(url, {
				screenId: screenId,
				fieldId: Number(fieldId),
				label: value,
				type: type,
			})
			.then((response) => {
				console.log("theme", response.data);
				getPageField();
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	};
	const setTask = async () => {
		const url =
			APIURLConstants.CREATE_TASK +
			"/" +
			Number(localStorage.getItem("userId"));
		await axios
			.get(url, {
				userId: Number(localStorage.getItem("userId")),
			})
			.then((response) => {
				console.log("Task", response.data);
				navigate("/acknoweldge");
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	};

	React.useEffect(() => {
		getPageField();
	}, []);

	return (
		<>
			<Box
				width={"100%"}
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{data &&
					sortBy(data, "screen").map((key, index) => {
						return (
							<Box key={index}>
								{index !== 0 && (
									<Divider
										// variant={"middle"}
										sx={{ border: 1, m: 2, width: "100%" }}
									/>
								)}
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										// justifyContent: "space-between",
									}}
								>
									{id === "vendor" && (
										<Box sx={{ width: "500px" }}>
											{index === 0 && (
												<Box
													display={"flex"}
													sx={{ background: theme.palette.primary.main }}
													width={220}
													// height={30}
													p={1}
												>
													<Typography
														variant="body1"
														color="initial"
														fontWeight={600}
													>
														{"Functional Configuration"}
													</Typography>
												</Box>
											)}

											<CommonConfig
												data={key.value.sort((a, b) => a.id - b.id)}
												// data={key.value}
												screen={Number(key.screen)}
												handlePopup={handlePopup}
												handleSubmit={setFieldConfig}
											/>
										</Box>
									)}

									<Box sx={{ width: id !== "vendor" ? "800px" : "500px" }}>
										<Common
											data={key.value}
											screen={
												Number(key.screen) === 1
													? "Personal Details"
													: Number(key.screen) === 2
													? "Login Details"
													: Number(key.screen) === 3
													? "Admin Details"
													: "Intermediaries Terms, Conditions and Privacy Notice"
											}
											color={id !== "vendor" ? "#fff" : "#606060"}
											alignLeft={12}
										/>
									</Box>

									{/* {id !== undefined && (
										<CommonConfig
											data={key.value.sort((a, b) => a.id - b.id)}
											// data={key.value}
											screen={Number(key.screen)}
											handlePopup={handlePopup}
											handleSubmit={setFieldConfig}
										/>
									)} */}
								</Box>
							</Box>
						);
					})}
				{id !== "config" && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							mt: "15px",
							py: "15px",
							justifyContent: "space-between",
							width: "800px",
						}}
					>
						{localStorage.getItem("role") === "manager" ? (
							<Button
								variant="contained"
								color="primary"
								sx={{ textTransform: "unset", borderRadius: "8px" }}
								startIcon={
									<Box sx={{ display: "flex", flexDirection: "row" }}>
										<NavigateBefore />
									</Box>
								}
								onClick={() => {
									// alert("published");
									navigate("/task");
								}}
							>
								Publish
							</Button>
						) : (
							<>
								<Button
									variant="contained"
									color="primary"
									sx={{ textTransform: "unset", borderRadius: "8px" }}
									startIcon={
										<Box sx={{ display: "flex", flexDirection: "row" }}>
											<NavigateBefore />
										</Box>
									}
									onClick={() => {
										navigate("/");
									}}
								>
									Back
								</Button>
								<Button
									variant="contained"
									color="primary"
									sx={{ textTransform: "unset", borderRadius: "8px" }}
									endIcon={
										<Box sx={{ display: "flex", flexDirection: "row" }}>
											<NavigateNext />
										</Box>
									}
									onClick={() => {
										// navigate("/acknoweldge");
										setTask();
									}}
								>
									{localStorage.getItem("role") === "admin"
										? "Send for Approval"
										: "submit"}
								</Button>
							</>
						)}
					</Box>
				)}
			</Box>
			{open && (
				<CustomModal
					screen={screenName}
					data={popUpData}
					handleClose={handleClose}
					handleSubmit={setFieldConfig}
				/>
			)}
		</>
	);
};
