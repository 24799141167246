import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";

export default function CheckboxComponent(props) {
	// console.log("checkbox", props);
	return (
		<Box display={"flex"}>
			<FormGroup>
				<FormControlLabel control={<Checkbox />} label={props.label} />
			</FormGroup>
		</Box>
	);
}
