import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

export default function RadioComponent(props) {
	return (
		<FormControl>
			<FormLabel id="demo-row-radio-buttons-group-label">
				{props.label}
			</FormLabel>
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group"
			>
				{JSON.parse(props.data.fieldJson) &&
					JSON.parse(props.data.fieldJson).map((key) => {
						return (
							<FormControlLabel
								value={key.id}
								control={<Radio color="primary" />}
								label={key.key}
								key={key.id}
							/>
							// <FormControlLabel value={key.id} key={key.id} label={key.key} />
						);
					})}
			</RadioGroup>
		</FormControl>
	);
}
