import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectComponent(props) {
	const [value, setValue] = React.useState();

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "flex-start",
				alignItems: "flex-start",
				my: 1,
			}}
		>
			<FormControl fullWidth>
				<InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={value}
					defaultValue={1}
					label={props.label}
					onChange={handleChange}
					size="small"
					sx={{ width: "150px" }}
				>
					{props.data.fieldJson &&
						JSON.parse(props.data.fieldJson).map((key, index) => {
							return (
								<MenuItem value={key.id} key={index}>
									{key.key}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		</Box>
	);
}
