import React from "react";
import {
	Typography,
	Box,
	List,
	ListItemText,
	ListItem,
	Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";

export const Acknoweldge = () => {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				justifyContent: "center",
				alignContent: "center",
				textAlign: "center",
				alignItems: "center",
			}}
		>
			{localStorage.getItem("role") === "admin" ? (
				<Typography fontWeight={600} width={"500px"}>
					{"Form changes has beed send to approval!"}
				</Typography>
			) : (
				<>
					<Typography fontWeight={600} width={"500px"}>
						{"Thank you for registering with Amethyst Building Society !"}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// width: "100%",
							justifyContent: "center",
							alignContent: "center",
						}}
					>
						<List
							sx={{
								listStyleType: "disc",
								listStylePosition: "inside",
								display: "flex",
								flexDirection: "column",
								textAlign: "center",
								alignSelf: "center",
							}}
						>
							<ListItem sx={{ display: "list-item", width: "500px" }}>
								{"You can log in straight away and start your applications."}
							</ListItem>
							<ListItem sx={{ display: "list-item", width: "500px" }}>
								{
									"We will get in touch with you to finish your registration process."
								}
							</ListItem>
							<ListItem sx={{ display: "list-item", width: "500px" }}>
								{
									"You will be able to submit your applications as soon as your registration is completed."
								}
							</ListItem>
						</List>
						<Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
							<Button
								variant="contained"
								color="primary"
								sx={{
									textTransform: "unset",
									borderRadius: "8px",
									width: "150px",
									display: "flex",
									flexDirection: "row-reverse",
								}}
								startIcon={
									<Box sx={{ display: "flex", flexDirection: "row" }}>
										<NavigateNext />
									</Box>
								}
								onClick={() => {
									navigate("/");
								}}
							>
								Login
							</Button>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};
