import React, { useEffect } from "react";
import { sortBy } from "lodash";
import { TextField, Box, Select, Typography } from "@mui/material";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import RadioComponent from "./RadioComponent";
import LinkComponent from "./LinkComponent";
import CheckboxComponent from "./CheckboxComponent";
import { CommonConfig } from "./CommonConfig";
import { useTheme } from "@mui/material/styles";

export const Common = (props) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				textAlign: "start",
				background: props.color,
				mx: props.alignLeft,
				p: 1,

				// width: "500px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					// width: "500px",
					textAlign: "start",
					// ml: 2,
				}}
			>
				<Box display={"flex"}>
					<Typography variant="body1" color="initial" fontWeight={600}>
						{props.screen}
					</Typography>
				</Box>
				<Box>
					{props.data &&
						props.data.length > 0 &&
						sortBy(props.data, "order").map((key, index) => {
							{
								if (key.visible === 1) {
									if (
										key.fieldType === "email" ||
										key.fieldType === "pwd" ||
										key.fieldType === "number" ||
										key.fieldType === "text"
									) {
										return (
											<InputComponent
												data={key}
												key={index}
												label={key.label}
												placholder={key.placeholder}
											/>
										);
									}
									if (key.fieldType === "select") {
										return (
											<SelectComponent
												data={key}
												width={120}
												key={index}
												label={key.label}
												placholder={key.placeholder}
											/>
										);
									}
									if (key.fieldType === "radio") {
										return (
											<RadioComponent
												data={key}
												width={120}
												key={index}
												label={key.label}
												placholder={key.placeholder}
											/>
										);
									}
									if (key.fieldType === "label") {
										return (
											<Typography key={index} sx={{ my: 1 }}>
												{key.label}
											</Typography>
										);
									}
									if (key.fieldType === "checkbox") {
										return (
											<CheckboxComponent
												data={key}
												key={index}
												label={key.label}
												placholder={key.placeholder}
											/>
										);
									}
									if (key.fieldType === "link") {
										return (
											<LinkComponent
												data={key}
												key={index}
												label={key.label}
												placholder={key.placeholder}
											/>
										);
									}
								}
							}
						})}
				</Box>
			</Box>
		</Box>
	);
};
