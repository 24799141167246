import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/system";
import { Button, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CheckboxComponent from "./CheckboxComponent";
import Checkbox from "@mui/material/Checkbox";
import CustomModal from "./CustomModal";
import InputComponent from "./InputComponent";
import RegistrationConfig from "./RegistrationConfig";

export const CommonConfig = (props) => {
	const theme = useTheme();
	const handlePopup = (screen, data) => {
		props.handlePopup(screen, data);
	};
	const handleInput = (value, fieldId, screenId, type) => {
		// console.log("we are here");
		props.handleSubmit(value, screenId, fieldId, type, "url");
	};
	const handleTextInput = (value, fieldId, screenId, type) => {
		// console.log("we are here");
		// props.handleSubmit(value, screenId, fieldId, type, "url");
		props.handleSubmit(value, screenId, fieldId, type, "label");
	};
	const handleChange = (value, screenId, fieldId, type, fieldName) => {
		const obj = { [fieldName]: value };
		props.handleSubmit(JSON.stringify(obj), fieldId, screenId, type, "check");
	};

	return (
		<Box
			sx={{
				// backgroundColor: theme.palette.secondary.main,
				width: 250,
				minHeight: 200,
				p: 2,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			{props.screen === 1 && (
				<>
					<RegistrationConfig
						data={props.data}
						handleSubmit={handleTextInput}
					/>
					{/* <Button variant="contained">
						<Typography fontSize={12} textTransform={"capitalize"}>
							{"Functional Configuration"}
						</Typography>
					</Button> */}
				</>
			)}
			{props.screen === 2 && (
				<Box sx={{ textAlign: "start" }}>
					<RegistrationConfig
						data={props.data}
						handleSubmit={handleTextInput}
					/>

					<Box>
						<Typography fontSize={12} textTransform={"capitalize"}>
							{"Password Configuration"}
						</Typography>
						<FormGroup>
							<Box sx={{ display: "flex", flexDirection: "row" }}>
								<Typography fontSize={12} textTransform={"capitalize"}>
									<Checkbox
										size="small"
										checked={
											props.data[1] && props.data[1].fieldLogic
												? JSON.parse(props.data[1].fieldLogic).alphanumeric
												: false
										}
										onChange={(e) => {
											handleChange(
												e.target.checked,
												props.data[1].screeId,
												props.data[1].id,
												null,
												"alphanumeric"
											);
										}}
									/>
									{"Alphanumeric"}
								</Typography>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									// justifyContent: "center",
									textAlign: "center",
									alignItems: "center",
								}}
							>
								<Typography fontSize={12} textTransform={"capitalize"}>
									<Checkbox
										size="small"
										checked={
											props.data[1] && props.data[1].fieldLogic
												? JSON.parse(props.data[1].fieldLogic).specialCharacter
												: false
										}
										onChange={(e) => {
											handleChange(
												e.target.checked,
												props.data[2].screeId,
												props.data[2].id,
												null,
												"specialCharacter"
											);
										}}
									/>

									{"Special character mandatory"}
								</Typography>
							</Box>
						</FormGroup>
					</Box>
				</Box>
			)}
			{props.screen === 3 && (
				<>
					<RegistrationConfig
						data={props.data}
						handleSubmit={handleTextInput}
					/>
					<FormGroup
						sx={{
							display: "flex",
							flexDirection: "row",
							// justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<Typography fontSize={12} textTransform={"capitalize"}>
							{"Ask admin details"}
						</Typography>
						<Switch
							checked={JSON.parse(props.data[0].fieldLogic).adminDetails}
							onChange={(e) => {
								handleChange(
									e.target.checked,
									props.data[0].screeId,
									props.data[0].id,
									null,
									"adminDetails"
								);
							}}
						/>
					</FormGroup>
				</>
			)}
			{props.screen === 4 && (
				<>
					<RegistrationConfig
						data={props.data}
						handleSubmit={handleTextInput}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							// justifyContent: "center",
							// textAlign: "center",
							// alignItems: "center",
							// alignContent: "center",
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography fontSize={12} textTransform={"capitalize"}>
								{"Link to intermediary terms and conditions."}
							</Typography>

							<InputComponent
								data={props.data[0]}
								// key={index}
								label={"url"}
								placholder="title"
								value={props.data[0].url}
								handleInput={handleInput}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								textAlign: "start",
							}}
						>
							<Typography fontSize={12} textTransform={"capitalize"}>
								{"Link to Privacy policy"}
							</Typography>

							<InputComponent
								data={props.data[1]}
								// key={index}
								label={"url"}
								placholder="title"
								value={props.data[1].url}
								handleInput={handleInput}
							/>
						</Box>
					</Box>
				</>
			)}
			<Box>
				{props.screen === 2 && (
					<FormGroup
						sx={{
							display: "flex",
							flexDirection: "row",
							// justifyContent: "center",
							textAlign: "center",
							alignItems: "center",
						}}
					>
						<Typography fontSize={12} textTransform={"capitalize"}>
							{"Ask memorable word"}
						</Typography>

						<Switch
							checked={props.data[3] && props.data[3].visible ? true : false}
							onChange={(e) => {
								// handleChange(
								// 	e.target.checked,
								// 	props.data[3].screeId,
								// 	props.data[3].id,
								// 	null,
								// 	"visible"
								// );
								props.handleSubmit(
									e.target.checked,
									props.data[3].id,
									props.data[3].screeId,
									null,
									"visible"
								);

								props.handleSubmit(
									e.target.checked,
									props.data[4].id,
									props.data[4].screeId,
									null,
									"visible"
								);
							}}
						/>
					</FormGroup>
				)}
				{/* <Button variant="text">
					<Typography
						fontSize={12}
						textTransform={"capitalize"}
						color={"blue"}
						onClick={() => {
							handlePopup(props.screen, props.data);
						}}
						// onClick={handleOpen}
					>
						{"Edit form labels and tooltip text"}
					</Typography>
				</Button> */}
			</Box>
		</Box>
	);
};
