import React from "react";
import { Divider, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import socailthumb from "../assets/brand/socailthumb.png";
import { useTheme } from "@mui/system";
export const Footer = () => {
	const theme = useTheme();
	const useCase = [
		"UI design",
		"UX design",
		"Wireframing",
		"Diagramming",
		"Brainstorming",
		"Online whiteboard",
		"Team collaboration",
	];
	return (
		<>
			<Box sx={{ py: "30px", px: "30px", display: "flex" }}>
				<Box sx={{ width: "30%", display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Box
							component={"img"}
							src={socailthumb}
							width={"18px"}
							alt="image"
						/>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", mt: "8px" }}>
						<XIcon />
						<InstagramIcon />
						<YouTubeIcon />
						<LinkedInIcon />
					</Box>
				</Box>
				<Box
					sx={{
						width: "23%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
					}}
				>
					<Box sx={{ display: "flex" }}>
						<Typography fontWeight={600} color="initial">
							{"Use cases"}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", flexDirection: "column", mt: "8px" }}>
						{useCase &&
							useCase.map((key) => {
								return (
									<Box sx={{ display: "flex" }} key={key}>
										<Typography>{key}</Typography>
									</Box>
								);
							})}
					</Box>
				</Box>
				<Box
					sx={{
						width: "23%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
					}}
				>
					<Box sx={{ display: "flex" }}>
						<Typography fontWeight={600} color="initial">
							{"Use cases"}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", flexDirection: "column", mt: "8px" }}>
						{useCase &&
							useCase.map((key) => {
								return (
									<Box sx={{ display: "flex" }} key={key}>
										<Typography>{key}</Typography>
									</Box>
								);
							})}
					</Box>
				</Box>
				<Box
					sx={{
						width: "23%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
					}}
				>
					<Box sx={{ display: "flex" }}>
						<Typography fontWeight={600} color="initial">
							{"Use cases"}
						</Typography>
					</Box>

					<Box sx={{ display: "flex", flexDirection: "column", mt: "8px" }}>
						{useCase &&
							useCase.map((key) => {
								return (
									<Box sx={{ display: "flex" }} key={key}>
										<Typography>{key}</Typography>
									</Box>
								);
							})}
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					height: "150px",
					marginRight: "-11px",
					marginTop: "0px",
					color: theme.palette.primary.main,
				}}
			>
				<img
					// src="https://www.w3schools.com/cssref/w3css.gif"
					alt="."
					width="100%"
					style={{
						clipPath:
							"polygon(88% 5%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 40%)",
						background: theme.palette.primary.main,
					}}
				/>
			</Box>
		</>
	);
};
