import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { sortBy } from "lodash";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import RadioComponent from "./RadioComponent";
import LinkComponent from "./LinkComponent";
import CheckboxComponent from "./CheckboxComponent";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "white",
	border: "2px solid #000",
	boxShadow: 24,
	p: 8,
};

export default function CustomModal(props) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		props.handleClose();
	};
	const handleInput = (value, fieldId, screeId, type) => {
		props.handleSubmit(value, screeId, fieldId, type, "label");
	};
	const handleChange = (value, fieldId, screeId, type) => {
		console.log("fieldId", fieldId);
		console.log("screeId", screeId);

		props.handleSubmit(value, screeId, fieldId, type, true);
	};

	return (
		<Modal
			open={true}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				{props.data &&
					props.data.length > 0 &&
					sortBy(props.data, "order").map((key, index) => {
						{
							if (
								key.fieldType === "email" ||
								key.fieldType === "pwd" ||
								key.fieldType === "number" ||
								key.fieldType === "text"
							) {
								return (
									<Box display="flex" gap={2}>
										<InputComponent
											data={key}
											key={index}
											handleInput={handleInput}
											label={key.label}
											placholder="title"
										/>
										<InputComponent
											data={key}
											key={index}
											handleInput={handleInput}
											placholder="tooltip"
											label={key.label}
										/>
									</Box>
								);
							}
							if (key.fieldType === "select") {
								return (
									<Box display="flex" gap={2}>
										{/* <SelectComponent data={key} width={120} key={index} />
										 */}
										<InputComponent
											data={key}
											key={index}
											label={key.label}
											placholder="title"
											handleInput={handleInput}
										/>
										<InputComponent
											data={key}
											key={index}
											placholder="tooltip"
											handleInput={handleInput}
											label={key.label}
										/>
									</Box>
								);
							}
							if (key.fieldType === "radio") {
								return (
									<Box display="flex">
										{/* <RadioComponent data={key} width={120} key={index}  
                                        handleInput={handleInput}
                                        />; */}
										<InputComponent
											data={key}
											key={index}
											placholder="title"
											label={key.label}
											handleInput={handleInput}
										/>
										<InputComponent
											data={key}
											key={index}
											placholder="tooltip"
											label={key.label}
											handleInput={handleInput}
										/>
									</Box>
								);
							}
							if (key.fieldType === "label") {
								return (
									<Box display="flex" gap={2}>
										{" "}
										{/* <Typography key={index} sx={{ my: 1 }}>
											{key.label}
										</Typography> */}
										<InputComponent
											data={key}
											key={index}
											placholder="title"
											label={key.label}
											handleInput={handleInput}
										/>
										<InputComponent
											data={key}
											key={index}
											label={key.label}
											placholder="tooltip"
											handleInput={handleInput}
										/>
									</Box>
								);
							}
							if (key.fieldType === "checkbox") {
								return (
									<Box display="flex" gap={2}>
										{/* <CheckboxComponent data={key} key={index} />; */}
										<InputComponent
											data={key}
											key={index}
											placholder="title"
											label={key.label}
											handleInput={handleInput}
										/>
										<InputComponent
											data={key}
											key={index}
											placholder="tooltip"
											label={key.label}
											handleInput={handleInput}
										/>
									</Box>
								);
							}
							if (key.fieldType === "link") {
								return (
									<Box display="flex" gap={2}>
										{/* <LinkComponent data={key} key={index} />; */}
										<InputComponent
											data={key}
											key={index}
											placholder="title"
											label={key.label}
											handleInput={handleInput}
										/>
										<InputComponent
											data={key}
											key={index}
											placholder="tooltip"
											label={key.label}
											handleInput={handleInput}
										/>
									</Box>
								);
							}
						}
					})}
			</Box>
		</Modal>
	);
}
