import React from "react";
import APIURLConstants from "../config/config";
import axios from "axios";

class ThemeServices {
	async getTheme() {
		var themeOptions = {
			method: "get",
			url: APIURLConstants.GET_THEME,
			headers: {
				"Content-Type": "application/json",
			},
			json: true,
		};
		await axios(themeOptions)
			.then((response) => {
				// console.log("theme", response.data[0]);
				return response.data[0];
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	}
}
const themeService = new ThemeServices();
export { themeService };
