import React from "react";
import { Box } from "@mui/material";
import {
	TextField,
	Button,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Typography,
	Grid,
	Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import APIURLConstants from "../config/config";
import { lightBlue } from "@mui/material/colors";
import moment from "moment";
export const Task = () => {
	let navigate = useNavigate();
	const [emailErr, setEmailErr] = React.useState("");
	const [passwordErr, setPasswordErr] = React.useState("");
	const [task, setTask] = React.useState([]);
	const [date, setDate] = React.useState(new Date());
	const [comment, setComment] = React.useState("");

	const handleTask = async (id, approved) => {
		const url =
			APIURLConstants.UPDATE_TASK +
			"/" +
			id +
			"/" +
			Number(localStorage.getItem("userId")) +
			"/" +
			comment +
			"/" +
			approved;
		await axios
			.get(url, {
				userId: Number(localStorage.getItem("userId")),
			})
			.then((response) => {
				console.log("Task", response.data);

				// navigate("/acknoweldge");
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	};

	const getTask = async () => {
		await axios
			.post(APIURLConstants.GET_TASK)
			.then((res) => {
				if (res.data) {
					setTask(res.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	React.useEffect(() => {
		getTask();
	}, []);

	return (
		<>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: { xs: "column", md: "column" },
					justifyContent: "flex-start",
					p: { xs: 0, md: 4 },
					minHeight: "130px",
					borderRadius: "5rem",
					border: "1px solid white",
				}}
			>
				<Typography
					component="h3"
					variant="h5"
					textAlign={"start"}
					fontWeight={600}
					sx={{ ml: "4rem" }}
				>
					Task
				</Typography>
				<Box
					sx={{
						width: "85%",
						display: "column",
						// justifyContent: "flex-start",
						minHeight: "130px",
						// background: "#F2F2F2",
						gap: 2,
					}}
				>
					{task && task.length > 0 ? (
						<>
							{task.map((key, index) => {
								return (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											m: 2,
											justifyContent: "space-between",
											height: 140,
											width: "50%",
											borderRadius: 1,
											p: 2,
											bgcolor: "lightBlue",
											// "&:hover": {
											// 	bgcolor: "primary.dark",
											// },
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "start",
											}}
										>
											<Typography
												component="h3"
												variant="subtitle1"
												// textAlign={"center"}
												fontWeight={600}
												sx={{ ml: "4rem", cursor: "pointer" }}
												onClick={() => navigate("/regsiter/vendor")}
											>
												{"Task " + (Number(index) + 1)}
											</Typography>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													alignContent: "center",
													textAlign: "center",
													alignItems: "center",
												}}
											>
												{/* <Typography
													component="h2"
													variant="subtitle1"
													// textAlign={"center"}
													fontWeight={600}
													sx={{ ml: "4rem" }}
													// onClick={() => navigate("/regsiter/vendor")}
												>
													{"Comments: "}
												</Typography> */}
												<TextField
													id="outlined-basic"
													variant="outlined"
													label={"Comment"}
													placeholder={"Comments"}
													size="medium"
													fullWidth
													sx={{ borderRadius: "8px", my: 1, width: "400px" }}
													value={comment}
													onChange={(e) => {
														// props.handleInput(e.target.value, props);
														setComment(e.target.value);
													}}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "center",
													gap: 2,
												}}
											>
												<Button
													variant="contained"
													sx={{
														textTransform: "unset",
														borderRadius: "8px",
													}}
													color="success"
													onClick={() => {
														// navigate("/acknoweldge");
														handleTask(key.id, 1);
													}}
												>
													{"Approved"}
												</Button>
												<Button
													variant="contained"
													color="error"
													sx={{ textTransform: "unset", borderRadius: "8px" }}
													onClick={() => {
														// navigate("/acknoweldge");
														handleTask(key.id, 0);
													}}
												>
													{"Rejected"}
												</Button>
											</Box>
										</Box>

										<Typography
											component="h3"
											variant="subtitle1"
											textAlign={"center"}
											fontWeight={400}
											fontSize={12}
											sx={{ ml: "4rem" }}
										>
											{"Created on " + moment(date).format("MMM Do YY")}
										</Typography>
									</Box>
								);
							})}
						</>
					) : (
						<Typography
							component="h3"
							variant="subtitle1"
							textAlign={"center"}
							fontWeight={600}
							sx={{ ml: "4rem" }}
						>
							There is no task created
						</Typography>
					)}
				</Box>
			</Box>
		</>
	);
};
