import axios from "axios";
import React from "react";

class FcaServices {
	getCompanyName(fca) {
		var authOptions = {
			method: "post",
			url: "https://register.fca.org.uk/services/V0.1/Firm/" + fca + "/Names",
			headers: {
				"Content-Type": "application/json",
				"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
				"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
			},
			json: true,
		};
		axios(authOptions)
			.then((response) => {
				console.log(response);
				return response.data;
			})
			.catch((error) => {
				console.log(error);
				return error;
			});
	}
	getFca(fca) {
		var authOptions = {
			method: "post",
			url: "https://register.fca.org.uk/services/V0.1/Firm/" + fca,
			headers: {
				"Access-Control-Allow-Origin": "*",
				Accept: "*",
				mode: "no-cors",
				"Content-Type": "application/json",
				"Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
				"Access-Control-Allow-Credentials": false,
				"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
				"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
				"Access-Control-Allow-Headers":
					"Accept, X-Requested-With, Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Origin, Content-Type, X-Auth-Token,Authorization",
			},
			// json: true,
		};
		axios(authOptions)
			.then((response) => {
				console.log(response);

				return response?.data;
			})
			.catch((error) => {
				return error;
			});
	}
	async getCompanyAddress(fca) {
		var authOptions = {
			method: "post",
			url: "https://register.fca.org.uk/services/V0.1/Firm/" + fca + "/Address",
			headers: {
				"Content-Type": "application/json",
				"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
				"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
			},
			json: true,
		};
		await axios(authOptions)
			.then((response) => {
				console.log(response);
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				return error;
			});
	}
	getCompanyPermission(fca) {
		var authOptions = {
			method: "post",
			url:
				"https://register.fca.org.uk/services/V0.1/Firm/" +
				fca +
				"/Permissions",
			headers: {
				"Content-Type": "application/json",
				"X-AUTH-KEY": "5c8c406fc8efc8b7e2569341346537c7",
				"X-AUTH-EMAIL": "shruti.mopo@gmail.com",
			},
			json: true,
		};
		axios(authOptions)
			.then((response) => {
				console.log(response);
				return response.data;
			})
			.catch((error) => {
				console.log(error);

				return error;
			});
	}
}
const fcaService = new FcaServices();
export { fcaService };
