import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

const preventDefault = (event) => event.preventDefault();

export default function LinkComponent(props) {
	const hereFlag = JSON.parse(props.data.fieldJson);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
			}}
			onClick={preventDefault}
		>
			<Typography>
				{props.label} {hereFlag.here && <Link href="#">here</Link>}
			</Typography>
		</Box>
	);
}
