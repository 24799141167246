import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function InputComponent(props) {
	const [value, setValue] = React.useState(props.value);

	return (
		<Box autoComplete="off" sx={{ textAlign: "start" }}>
			<TextField
				id="outlined-basic"
				variant="outlined"
				label={props.label}
				placeholder={props.placholder}
				size="small"
				sx={{ borderRadius: "8px", my: 1 }}
				value={value}
				onChange={(e) => {
					// props.handleInput(e.target.value, props);
					setValue(e.target.value);
				}}
				onKeyDown={(ev) => {
					console.log(`Pressed keyCode ${ev.key}`);
					if (ev.key === "Enter") {
						// Do code here
						props.handleInput(
							value,
							props.data.screenId,
							props.data.id,
							props.placholder
						);
					}
				}}
			/>
		</Box>
	);
}
