import * as React from "react";
import Box from "@mui/material/Box";
import { sortBy } from "lodash";
import InputComponent from "./InputComponent";

export default function RegistrationConfig(props) {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		props.handleClose();
	};
	const handleInput = (value, fieldId, screenId, type) => {
		console.log("value", value);
		console.log("fieldId", fieldId);
		console.log("screeId", screenId);
		console.log("type", type);

		props.handleSubmit(value, fieldId, screenId, type, "label");
	};
	const handleChange = (value, fieldId, screeId, type) => {
		props.handleSubmit(value, screeId, fieldId, type, true);
	};

	return (
		<Box>
			{props.data &&
				props.data.length > 0 &&
				sortBy(props.data, "order").map((key, index) => {
					{
						if (
							key.fieldType === "email" ||
							key.fieldType === "pwd" ||
							key.fieldType === "number" ||
							key.fieldType === "text"
						) {
							return (
								<Box display="flex">
									<InputComponent
										data={key}
										key={index}
										handleInput={handleInput}
										label={key.label}
										placholder="title"
									/>
									{/* <InputComponent
										data={key}
										key={index}
										handleInput={handleInput}
										placholder="tooltip"
										label={key.label}
									/> */}
								</Box>
							);
						}
						if (key.fieldType === "select") {
							return (
								<Box display="flex">
									{/* <SelectComponent data={key} width={120} key={index} />
									 */}
									<InputComponent
										data={key}
										key={index}
										label={key.label}
										placholder="title"
										handleInput={handleInput}
									/>
									{/* <InputComponent
										data={key}
										key={index}
										placholder="tooltip"
										handleInput={handleInput}
										label={key.label}
									/> */}
								</Box>
							);
						}
						if (key.fieldType === "radio") {
							return (
								<Box display="flex">
									{/* <RadioComponent data={key} width={120} key={index}  
                                        handleInput={handleInput}
                                        />; */}
									<InputComponent
										data={key}
										key={index}
										placholder="title"
										label={key.label}
										handleInput={handleInput}
									/>
									{/* <InputComponent
										data={key}
										key={index}
										placholder="tooltip"
										label={key.label}
										handleInput={handleInput}
									/> */}
								</Box>
							);
						}
						if (key.fieldType === "label") {
							return (
								<Box display="flex">
									{" "}
									{/* <Typography key={index} sx={{ my: 1 }}>
											{key.label}
										</Typography> */}
									<InputComponent
										data={key}
										key={index}
										placholder="title"
										label={key.label}
										handleInput={handleInput}
									/>
									{/* <InputComponent
										data={key}
										key={index}
										label={key.label}
										placholder="tooltip"
										handleInput={handleInput}
									/> */}
								</Box>
							);
						}
						if (key.fieldType === "checkbox") {
							return (
								<Box display="flex">
									{/* <CheckboxComponent data={key} key={index} />; */}
									<InputComponent
										data={key}
										key={index}
										placholder="title"
										label={key.label}
										handleInput={handleInput}
									/>
									{/* <InputComponent
										data={key}
										key={index}
										placholder="tooltip"
										label={key.label}
										handleInput={handleInput}
									/> */}
								</Box>
							);
						}
						if (key.fieldType === "link") {
							return (
								<Box display="flex">
									{/* <LinkComponent data={key} key={index} />; */}
									<InputComponent
										data={key}
										key={index}
										placholder="title"
										label={key.label}
										handleInput={handleInput}
									/>
									{/* <InputComponent
										data={key}
										key={index}
										placholder="tooltip"
										label={key.label}
										handleInput={handleInput}
									/> */}
								</Box>
							);
						}
					}
				})}
		</Box>
	);
}
