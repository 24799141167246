import * as React from "react";
// import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import { Box, Link } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import logo from "../assets/brand/logo.png";
import { useTheme } from "@mui/material/styles";

export default function SearchAppBar() {
	console.log("search app");
	const theme = useTheme();
	// const history=useH

	return (
		<Box sx={{ flexGrow: 1, width: "100%" }}>
			<AppBar position="static">
				<Box sx={{ marginTop: "10px" }}>
					<Toolbar
						sx={{
							display: "flex",
							flexDirection: "column",
							height: "60px",
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
							<Box sx={{ height: "60px", width: "20%" }}>
								<Box
									component={"img"}
									src={logo}
									sx={{ height: "60px" }}
									alt="image"
								/>
							</Box>
							<Box
								sx={{
									width: "80%",
									backgroundColor: "#fff",
									display: "flex",
									justifyContent: "end",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										widht: "100px",
										px: "20px",
										alignContent: "center",
										alignItems: "center",
									}}
								>
									<Box width={"100px"}>
										<Link href="#" underline="none">
											{"Criteria"}
										</Link>
									</Box>
									<Box width={"200px"}>
										<Link href="#" underline="none">
											{"Important documents"}
										</Link>
									</Box>
								</Box>
							</Box>
						</Box>
					</Toolbar>
				</Box>
			</AppBar>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					width: "100%",
					height: "100px",
					marginLeft: "-11px",
					marginTop: "0px",
					color: theme.palette.primary.main,
				}}
			>
				<img
					// src="https://www.w3schools.com/cssref/w3css.gif"
					alt="."
					width="100%"
					style={{
						clipPath: "polygon(100% 0%, 1% 0%, 16% 65%)",
						background: theme.palette.primary.main,
					}}
				/>
			</Box>
		</Box>
	);
}
